/* eslint-disable @typescript-eslint/camelcase */
export const translate = {
  channel: '渠道流量',
  'list_channel-analytics': '渠道流量統計',
  list_channels: '渠道列表',
  create_channel: '新增渠道',
  edit_channel: '編輯渠道',
  show_channel: '渠道詳情',
  delete_channel: '刪除渠道',
  list_campaigns: '渠道合作列表',
  create_campaign: '新增渠道合作',
  dictionary: '詞典',
  list_topics: '主題列表',
  create_topic: '新增主題',
  edit_topic: '編輯主題',
  delete_topic: '刪除主題',
  list_tags: '標籤列表',
  create_tag: '新增標籤',
  edit_tag: '編輯標籤',
  delete_tag: '刪除標籤',
  list_directors: '導演列表',
  create_director: '新增導演',
  edit_director: '編輯導演',
  delete_director: '刪除導演',
  list_actors: '演員列表',
  create_actor: '新增演員',
  edit_actor: '編輯演員',
  delete_actor: '刪除演員',
  list_genres: '影片類型列表',
  create_genre: '新增影片類型',
  edit_genre: '編輯影片類型',
  delete_genre: '刪除影片類型',
  landingPage: '落地頁管理',
  list_advertisements: '廣告列表',
  create_advertisement: '新增廣告',
  edit_advertisement: '編輯廣告',
  delete_advertisement: '刪除廣告',
  'list_landing-page-works': '落地頁影片列表',
  'create_landing-page-work': '新增落地頁預覽片',
  order: '訂單',
  list_orders: '訂單列表',
  permission: '權限',
  list_admins: '帳號列表',
  create_admin: '新增帳號',
  edit_admin: '編輯帳號',
  delete_admin: '刪除帳號',
  list_roles: '角色列表',
  create_role: '新增角色',
  edit_role: '編輯角色',
  delete_role: '刪除角色',
  plan: '訂閱方案',
  list_plans: '方案列表',
  edit_plan: '編輯方案',
  list_coupons: '體驗券列表',
  create_coupon: '新增優惠券',
  edit_coupon: '編輯優惠券',
  delete_coupon: '刪除優惠券',
  list_subscriptions: '訂閱列表',
  user: '會員',
  list_users: '會員列表',
  create_user: '新增會員',
  edit_user: '編輯會員',
  delete_user: '刪除會員',
  show_user: '查看會員詳情',
  'list_review-user': '年齡驗證列表',
  'edit_review-user': '審核年齡驗證',
  work: '影片',
  list_works: '單部影片列表',
  create_work: '新增影片',
  edit_work: '編輯影片',
  delete_work: '刪除影片',
  show_work: '查看影片詳情',
  'list_feature-works': '主打影片列表',
  'edit_feature-work': '選擇主打影片',
  'list_channel-coupon-usage': '渠道體驗卷統計',
  update_campaign: '渠道合作校正',
  list_analytics: '統計相關報表的權限',
  edit_subscriptions: '編輯訂閱',
  analytics: '分析報表',
  wte: 'WTE管理',
  list_events: '活動列表',
  create_event: '新增活動',
  edit_event: '編輯活動',
  list_suggestions: '建議列表',
  edit_suggestion: '審核建議',
  'list_events-data': '活動數據列表'
};
